var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"elevation":0,"fluid":""}},[_c('div',{staticClass:"d-flex mb-2",staticStyle:{"gap":"6px"}},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.getContactos.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" recargar ")],1),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.detailsId = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Nuevo contacto ")],1)],1),_c('v-data-table',{staticClass:"fixed-checkbox",attrs:{"headers":_vm.contactosHeaders,"items":_vm.contactos,"loading":_vm.loading,"single-select":false,"item-key":"idContacto","checkbox-color":"secondary","calculate-widths":true,"footer-props":{
      showFirstLastPage: true,
      showCurrentPage: true,
      itemsPerPageOptions: [25, 50, -1],
    }},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
    var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.contactos},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.tipo",fn:function(ref){
    var item = ref.item;
return [_c('StatusChip',{attrs:{"value":item.tipo}}),(item.respondida == 0)?_c('v-icon',{attrs:{"right":"","small":"","color":"error"}},[_vm._v("mdi-exclamation")]):_vm._e()]}},{key:"item.fechaContacto",fn:function(ref){
    var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.fechaContacto, false, true, true))}})]}},{key:"item.resumen",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"d-inline-block mr-1 text-truncate",staticStyle:{"max-width":"600px"},domProps:{"textContent":_vm._s(item.resumen)}}),_c('v-btn',{attrs:{"small":"","icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();_vm.detailsId = item.idContacto}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)]}},{key:"item.respondida",fn:function(ref){
    var item = ref.item;
return [(item.respondida == null || item.respondida == undefined)?_c('v-icon',{attrs:{"color":""}},[_vm._v("mdi-minus")]):(item.respondida)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-phone")]):(!item.respondida)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-phone-off")]):_vm._e()]}},{key:"item.acciones",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"6px"}},[_c('v-btn',{attrs:{"small":"","outlined":"","rounded":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();_vm.detailsId = item.idContacto}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye")]),_vm._v(" Detalles ")],1),_c('v-btn',{attrs:{"small":"","outlined":"","rounded":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.eliminarContacto(item.idContacto)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Eliminar ")],1)],1)]}}])}),_c('v-dialog',{attrs:{"value":_vm.detailsId,"max-width":"1100px"},on:{"input":function (v) { return (v === false ? (_vm.detailsId = null) : null); }}},[(_vm.detailsId != null && _vm.detailsId !== true)?_c('DetallesContacto',{attrs:{"contacto":_vm.contactos.find(function (c) { return c.idContacto == _vm.detailsId; })}}):_vm._e(),(_vm.detailsId === true)?_c('NuevoContacto',{key:_vm.detailsId,attrs:{"contacto":_vm.contactos.find(function (c) { return c.idContacto == _vm.detailsId; }),"idEstudio":_vm.idEstudio},on:{"reload":_vm.getContactos,"close":function($event){_vm.detailsId = null}}}):_vm._e()],1),_c('ConfirmDialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }